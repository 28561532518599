<template>
  <div class="margin-bottom-50">
    <div class="row">
      <div class="col-md-8 mx-auto">
        <div class="card border shadow">
          <div class="card-body mt-3 mb-3">
            <div class="row" v-if="loadingInformation">
              <div class="col-md-12">
                <shimmer :num="5"/>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-md-12" v-if="bankVerified === 'pending'">
                <div class="flex mb-3 items-center bg-blue-500 text-white text-sm font-bold px-4 py-3" role="alert">
                  <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                        d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/>
                  </svg>
                  <p>Bank account verification link has been sent to your mail.</p>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <h2 class="text-left">Bank information</h2>
                  <p class="text-left" style="font-size: 13px;margin-top: 4px">Kindly update your payment
                    information.</p>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">Bank name:</label>
                  <el-select style="width: 100%" filterable v-model="bank_info.bank_code"
                             v-on:change="OnResolveAccount">
                    <el-option :value="item.short_code" :label="item.name" v-for="(item,key) in bankList"
                               :key="key">
                      {{ item.name }}
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mt-2 mb-2" style="position: relative">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">Account number:</label>
                  <el-input placeholder="Account number" v-model="bank_info.account_number"
                            v-on:change="OnResolveAccount"
                            class="w-100"></el-input>
                  <span v-if="resolving_account" style="position: absolute;top: 36px;right: 8px;"><i
                      class="fa fa-spinner fa-spin"></i></span>
                  <span class="text-danger float-left pt-1 font-size-12"
                        v-if="bank_resolve_error">Failed to resolve account</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">Account name:</label>
                  <el-input placeholder="Account name" class="w-100" v-model="bank_info.account_name"
                            :disabled="true"></el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-button type="primary" native-type="button" :disabled="loading"
                   v-if="loadingInformation === false" v-on:click="submit"
                   style="margin-bottom: 100px;float: left">
          Update <i
            class="el-icon-loading ml-2" v-if="loading"></i>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import shimmer from "../../reusable/shimmer";
import {mapGetters} from "vuex"

export default {
  name: "BankDetails",
  components: {shimmer},
  data: function () {
    return {
      loadingInformation: false,
      bankVerified: '',
      loading: false,
      resolving_account: false,
      bank_resolve_error: false,
      bankList: [],
      bank_info: {
        "account_name": '',
        "account_number": '',
        "bank_code": '',
        "bank_name": '',
        "payout": 'daily',
      },
    }
  },
  methods: {
    getBanks: function () {
      this.$store.dispatch('general/getBankList').then(resp => {
        this.bankList = resp.data.data
      })
    },
    OnResolveAccount: function () {
      if (this.bank_info.account_number) {
        this.resolving_account = true
        this.$store.dispatch('general/verifyAccountNumber', {
          "account_number": this.bank_info.account_number,
          "bank_code": this.bank_info.bank_code
        }).then(resp => {
          this.resolving_account = false
          this.bank_resolve_error = false
          this.bank_info.account_name = resp.data.account_name
        }).catch(() => {
          this.resolving_account = false
          this.bank_resolve_error = true
          this.bank_info.account_holder = ''
        })
      }
    },
    getBusinessInfo: function () {
      this.loadingInformation = true

      this.$store.dispatch('user/GET_MERCHANT', {'username': this.GET_USER.username}).then(() => {
        this.loadingInformation = false
        this.bank_info = {
          "account_name": this.GET_MERCHANT_INFO?.account_name,
          "account_number": this.GET_MERCHANT_INFO?.account_number,
          "bank_code": this.GET_MERCHANT_INFO?.bank_code,
          "bank_name": this.GET_MERCHANT_INFO?.bank_name,
          "payout": this.GET_MERCHANT_INFO?.payout,
        }
      }).catch(() => {
        this.loadingInformation = false
      })
    },
    submit: function () {
      this.loading = true
      let selectedBank = this.bankList.filter(item => item.short_code === this.bank_info.bank_code)
      if (selectedBank.length > 0){
        this.bank_info.bank_name = selectedBank[0].name
      }
      this.$store.dispatch('user/UPDATE_MERCHANT_BANK_INFO', {
        'data': this.bank_info,
        'username': this.GET_USER.username
      })
          .then(res => {
            this.loading = false
            this.$toast.success({
              title: 'success',
              message: `Business information updated successfully`
            })
            this.bankVerified = res.data.data.is_bank_account_verified
          })
          .catch(error => {
            this.loading = false
            this.$toast.warn({
              title: 'warning',
              message: error.response.data.message
            })
          })
    },
  },
  mounted() {
    this.getBusinessInfo()
    this.getBanks()
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_MERCHANT_INFO: 'user/GET_MERCHANT_INFO'
    })
  }
}
</script>

<style scoped>
.font-size-12 {
  font-size: 12px
}

.text-danger {
  color: #df626e !important;
}
</style>