<template>
  <div class="h-full pb-20">
    <TableComponent :results="transactions.results" :total_pages="transactions.total_pages" :columns="columns"
                    @onResultChange="changeResultNumber"
                    @onChangePageNumber="changePageNumber"/>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TableComponent from "../../../../../components/Table"
import moment from "moment";
import {formatAmount} from "../../../../../utils";

export default {
  name: "dashboard",
  components: {TableComponent},
  data: function () {
    return {
      transactions: {results: [], total_pages: 0},
      columns: [
        {
          name: 'code',
          label: 'Code',
          type: 'field',
        },
        {
          name: 'customer_name',
          label: 'Customer Name',
          type: 'field',
        },
        {
          name: 'amount',
          label: 'Amount',
          type: 'custom',
          custom: (row) => {
            return `₦${formatAmount(row.amount)}`
          }
        },
        {
          name: 'date',
          label: 'Date Time',
          type: 'custom',
          custom: (row) => {
            if (!row.created_at) return ""
            return `${this.dateFormat(row.created_at)}`
          }
        },
      ],
      limit: 10,
      payload: {
        search: "",
        filter: "",

      }
    }
  },
  methods: {
    dateFormat: function (dt_created) {

      if (!dt_created) {
        return ''
      }
      return moment(dt_created).format('YYYY-MM-DD hh:mm a')
    },
    computeParam: function () {
      let page = `page=${this.currentPage}&page_size=${this.limit}`
      if (this.payload.search !== '') {
        page = page + `&search=${this.payload.search}`
      }
      if (this.payload.filter) {
        page = page + `&status=${this.payload.filter}`
      }
      return page
    },
    changePageNumber: function (value) {
      this.currentPage = value
      const page = this.computeParam()
      this.onMakeRequest(page)
    },
    onMakeRequest: function (page) {
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter_transaction/getMerchantTransactions', {
        data: `?${page}`,
        merchant: merchant
      }).then((response) => {
        this.loading = false
        this.transactions.results = response.data.results
        this.transactions.total_pages = response.data.total
      })
    },
    changeResultNumber: function (value) {
      this.limit = value
      const page = this.computeParam()
      this.onMakeRequest(page)
    },
    onSearch: function () {
      let page = this.computeParam()
      this.onMakeRequest(page)
    },
    getMerchantPayments: function () {
      this.loading = true
      const merchant = this.GET_USER.merchant.code
      this.$store.dispatch('meter_transaction/getMerchantTransactions', {
        data: `?page_size=${this.limit}`,
        merchant: merchant
      }).then((response) => {
        this.loading = false
        this.transactions.results = response.data.results
        this.transactions.total_pages = response.data.total
      }).catch(() => {
        this.loading = false
      })
    },
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_TARIFFS: 'meter/GET_TARIFFS'
    }),

  },
  mounted() {
    this.getMerchantPayments()
  }
}
</script>

<style scoped>

</style>