<template>
  <div>
    <form v-on:submit.prevent="submitForm">
      <div class="w-full">
        <div class="mt-3">
          <label class="mr-sm-2 label">Name</label>
          <el-input placeholder="Name" type="text" class="w-100"
                    v-model="currentMeter.name" required></el-input>
        </div>
        <div class="mt-3">
          <label class="mr-sm-2 label">Serial Number</label>
          <el-input placeholder="Serial Number" type="text" class="w-100"
                    v-model="currentMeter.serial_number" :disabled="!!currentMeter.code"
                    required></el-input>
        </div>
        <div class=" mt-3">
          <label class="mr-sm-2 label">Customer</label>
          <el-select v-model="currentMeter.customer_code" filterable searchable clearable placeholder="Customer"
                     class="justify-items-end w-full">
            <el-option
                v-for="(item,key) in GET_CUSTOMERS.results"
                :key="key"
                :label="`${item.first_name} ${item.last_name}`"
                :value="item.code">
            </el-option>
          </el-select>
        </div>
        <div class=" mt-3">
          <label class="mr-sm-2 label">Status</label>
          <el-select v-model="currentMeter.status" placeholder="Status"
                     class="justify-items-end w-full">
            <el-option
                v-for="(item,key) in statusList"
                :key="key"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="">
          <el-button type="primary" native-type="submit" class="mt-4"
                     :disabled="onSubmitLoading">Submit<i
              class="el-icon-loading ml-2" v-if="onSubmitLoading"></i>
          </el-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {errorFormat} from "../../../../utils";
import {mapGetters} from "vuex";

export default {
  name: "meterForm",
  props: {
    meter: {
      type: Object,

    }
  },
  data: function () {
    return {
      onSubmitLoading: false,
      statusList: [
        {
          value: true,
          label: 'Activate'
        },
        {
          value: false,
          label: 'Deactivate'
        },
      ],
      currentMeter: {
        name: '',
        serial_number: '',
        customer_code: '',
        status: true
      }
    }
  },
  methods: {
    submitForm: function () {
      const {code} = this.currentMeter
      const merchant = this.GET_USER.merchant.code
      this.onSubmitLoading = true
      if (code) {
        this.$store.dispatch('meter/updateMeter', {
          data: this.currentMeter,
          code: code,
          merchant: merchant
        }).then(() => {
          this.onSubmitLoading = false
          this.onOpenMeterForm = false
          this.$toast.success({
            'title': 'success',
            'message': "Meter updated successfully"
          })

        }).catch(err => {
          const message = errorFormat({error: err})
          this.onSubmitLoading = false
          this.$toast.warn({
            'title': 'warning',
            'message': message
          })
        })
      } else {
        this.$store.dispatch('meter/createMeter', {
          data: this.currentMeter,
          merchant: merchant
        }).then(() => {
          this.onSubmitLoading = false
          this.$toast.success({
            'title': 'success',
            'message': "Meter created successfully"
          })
          this.$emit('closeModal')
        }).catch(err => {
          this.onSubmitLoading = false
          const message = errorFormat({error: err})

          this.$toast.warn({
            'title': 'warning',
            'message': message
          })
        })
      }

    },

  },
  mounted() {
    this.currentMeter = JSON.parse(JSON.stringify(this.meter))

  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_CUSTOMERS: 'customers/GET_CUSTOMERS'
    }),

  },
}
</script>

<style scoped>

</style>