<template>
  <div class="margin-bottom-50">
    <div class="row">
      <div class="col-md-8 mx-auto">
        <div class="card border shadow">
          <div class="card-body mt-3 mb-3">
            <div class="row" v-if="loadingInformation">
              <shimmer :num="5"/>
            </div>
            <div class="row" v-else>
              <div class="col-md-12">
                <div class="mb-3">
                  <h2 class="text-left">Personal information</h2>
                  <p class="text-left" style="font-size: 13px;margin-top: 4px">Here's what we know about you. Please do
                    update it if there have been any recent changes.</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">First name:</label>
                  <el-input placeholder="First name" v-model="payload.first_name" class="w-100"></el-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">Last name:</label>
                  <el-input placeholder="Last name" v-model="payload.last_name" class="w-100"></el-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">Email:</label>
                  <el-input placeholder="Email" v-model="payload.email" :disabled="true" class="w-100"></el-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mt-2 mb-2">
                  <label class="label mb-1 !text-[14px]" style="margin-bottom: 2px !important;">Phone number:</label>
                  <el-input placeholder="Phone number" v-model="payload.mobile" class="w-100"></el-input>
                </div>
              </div>
              <div class="col-md-12">
                <div class="grid grid-cols-2 gap-3">
                  <div class="w-full">
                    <label class="label mb-1 !text-[14px] text-[#b4b4b5]" style="margin-bottom: 2px !important;">Password</label>
                    <el-input placeholder="Phone number" v-model="payload.mobile" type="password" :disabled="true"
                              class="w-100"></el-input>
                  </div>
                  <div class="col-3">
                    <el-button type="default" class="mt-4 ml-4" native-type="button" v-on:click="openChangeModal">
                      Update password
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <el-button type="primary" native-type="submit" v-on:click="submit" :disabled="loading"
                   v-if="loadingInformation === false"
                   style="margin-bottom: 100px;float: left">
          Update <i
            class="el-icon-loading ml-2" v-if="loading"></i>
        </el-button>
      </div>

    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex"
import shimmer from "../../reusable/shimmer";

export default {
  name: "PersonalDetails",
  components: {shimmer},
  data: function () {
    return {
      loading: false,
      loadingInformation: true,
      payload: {
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
      }
    }
  },
  methods: {
    getProfile: function () {
      this.loadingInformation = true
      this.$store.dispatch('user/GET_ACCOUNT', {'id': this.GET_USER.id}).then(() => {
        this.loadingInformation = false
      }).catch(() => {
        this.loadingInformation = false
      })
    },
    submit: function () {
      this.loading = true
      let form = this.payload
      for (const [key, value] of Object.entries(form)) {
        if (value === '' || value === null) {
          this.loading = false
          return this.$toast.warn({
            title: 'warning',
            message: `${key.replace('_', ' ')} is required`
          })
        }
      }
      this.$store.dispatch('user/UPDATE_ACCOUNT', {'data': form, 'id': this.GET_USER.id})
          .then(() => {
            this.loading = false
            this.$toast.success({
              title: 'success',
              message: `Account updated successfully`
            })

          })
          .catch(error => {
            this.loading = false
            this.$toast.warn({
              title: 'warning',
              message: error.response.data.message
            })
          })
    },
    openChangeModal: function () {
      window.Bus.$emit('open-password-modal')
    }
  },
  computed: {
    ...mapGetters({
      GET_USER: 'user/GET_USER',
      GET_COUNTRY: 'general/GET_COUNTRY',
      GET_STATE: 'general/GET_STATE'
    })
  },
  mounted() {
    this.getProfile()
    // this.getCountry()
    this.payload = {
      first_name: this.GET_USER.first_name,
      last_name: this.GET_USER.last_name,
      email: this.GET_USER.email,
      mobile: this.GET_USER.mobile,
    }
  }
}
</script>

<style scoped>
.margin-bottom-50 {
  margin-bottom: 50px;
}
</style>